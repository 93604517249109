<template>
	<div
		class="main-slider main-slider--news container main-slider__container"
		v-if="slides && slides.length > 0"
	>
		<swiper :options="swiperOption" ref="mySwiper">
			<swiperSlide
				class="main-slider__slide"
				v-for="(slide, videoIndex) in slides"
				:key="videoIndex"
			>
				<div class="main-slider__video" @click="index = videoIndex">
					<img
						:src="slide.thumbnail"
						:alt="slide.title"
						class="main-slider__video-bg"
					/>
					<p class="main-slider__video-text">
						{{ slide.title }}
					</p>
				</div>
			</swiperSlide>
		</swiper>

		<div
			class="main-slider__buttons main-slider__buttons--news main-slider__container container"
		>
			<button
				class="swiper-button-prev swiper-button-prev--video"
				slot="button-prev"
			>
				<svg
					width="12"
					height="22"
					viewBox="0 0 12 22"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M11.2593 21L1.25928 11L11.2593 1"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
				</svg>
			</button>

			<div
				class="swiper-pagination swiper-pagination--news swiper-pagination--video"
				slot="pagination"
			></div>

			<button
				class="swiper-button-next swiper-button-next--video"
				slot="button-next"
			>
				<svg
					width="12"
					height="22"
					viewBox="0 0 12 22"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M0.740725 1L10.7407 11L0.740723 21"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
				</svg>
			</button>
		</div>

		<CoolLightBox :items="slides" :index="index" @close="index = null">
		</CoolLightBox>
	</div>
</template>

<script>
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import CoolLightBox from 'vue-cool-lightbox'

import 'swiper/css/swiper.css'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'

export default {
	name: 'NewsSlider',

	components: {
		swiper,
		swiperSlide,
		CoolLightBox,
	},

	data() {
		return {
			index: null,
			swiperOption: {
				loop: false,
				pagination: {
					el: '.swiper-pagination--video',
				},
				navigation: {
					nextEl: '.swiper-button-next--video',
					prevEl: '.swiper-button-prev--video',
				},
				slidesPerView: 2,
				spaceBetween: 30,
				breakpoints: {
					870: {
						slidesPerView: 1,
					},
				},
			},
		}
	},

	computed: {
		swiper() {
			const videos = this.$store.getters.VIDEOS

			if (videos && videos.length > 0) {
				this.swiper.slideTo(1, 0, false)
				return this.$refs.mySwiper.$swiper
			} else {
				return null
			}
		},

		slides() {
			const videos = this.$store.getters.VIDEOS

			if (videos) {
				videos.forEach(video => {
					video.src = video.url
				})
			}

			return videos
		},
	},
	mounted() {
		this.$store.dispatch('GET_VIDEOS')
	},
}
</script>
